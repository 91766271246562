@import 'import';
$spacing: 20px;
$mobileSpacing: 20px;
$iconSize: 40px;
$iconMobileSize: 40px;

.ImageVideo {
  position: relative;

  &__imageItem {
    object-fit: cover;

    &:not(&_hidden) {
      position: relative !important;
    }

    &_hidden {
      visibility: hidden;
    }
  }

  &._disableControls .VideoContainer {
    pointer-events: none;
  }

  &__SizzleReelsControls {
    pointer-events: all;

    position: absolute;
    bottom: $spacing;

    width: 100%;
    height: $iconSize;
    padding: 0 $spacing;

    .Icon {
      position: absolute;
      top: 0;
      width: $iconSize;
      height: $iconSize;

      svg {
        width: $iconSize;
        height: $iconSize;
      }

      @media (max-width: $container-md1-width) {
        width: $iconMobileSize;
        height: $iconMobileSize;

        svg {
          width: $iconMobileSize;
          height: $iconMobileSize;
        }
      }
    }

    .-svg-file-PlaybackMute,
    .-svg-file-PlaybackMuteSmall {
      right: $spacing;

      @media (max-width: $container-md1-width) {
        right: $mobileSpacing;
      }
    }

    @media (max-width: $container-md1-width) {
      bottom: $mobileSpacing;
      height: $iconMobileSize;
      padding: 0 $mobileSpacing;
    }
  }
}
