@import 'import';
.root {
  position: relative;
  padding-bottom: 56.25%; // 16:9
}

.fillsParent {
  height: 100%;

  .video {
    height: 100%;
  }
}

.hidden {
  display: none;
}

.video {
  position: absolute;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
  }
}
